/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://js3ibpidqnghvgozqha5c25anq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kuusx2awljblbdndqu4clmh6vm",
    "aws_cognito_identity_pool_id": "us-east-2:3c1a3088-f99a-4cb9-90cc-fe030ce1eef8",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_2S3VxIWjK",
    "aws_user_pools_web_client_id": "64cgfo8ec82f1793fsilkok5gp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyrafstoragebucket23932-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
